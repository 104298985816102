import ReactPaginate from 'react-paginate';
import classes from '../style/pagination.module.scss'

export function PaginatedItems({ onChangePage, total, itemsPerPage, currentPage }: { onChangePage: (page: number) => void, total: number, itemsPerPage: number, currentPage: number }) {

    const pageCount = Math.ceil(total / itemsPerPage);

    const handlePageClick = async (event: { selected: number; }) => {
        onChangePage(event.selected)
    };

    return (
        <div className={classes.paginationBttns}>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                activeClassName={classes.active}
                forcePage={currentPage}
            />
        </div>
    );
}