// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login_loginForm__lHBAz {\n  width: 600px;\n  max-width: calc(100% - 40px);\n  margin: 100px auto;\n  padding: 0 20px;\n  border-radius: 10px;\n  box-shadow: 0px 0px 45px 19px rgba(171, 169, 171, 0.51);\n}", "",{"version":3,"sources":["webpack://./src/style/login.module.scss"],"names":[],"mappings":"AAIA;EACI,YAAA;EACA,4BAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,uDAAA;AAHJ","sourcesContent":["@import \"./variables/colors.scss\";\n@import \"./variables/breakpoints.scss\";\n\n\n.loginForm {\n    width: 600px;\n    max-width: calc(100% - 40px);\n    margin: 100px auto;\n    padding: 0 20px;\n    border-radius: 10px;\n    box-shadow: 0px 0px 45px 19px rgb(171 169 171 / 51%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginForm": "login_loginForm__lHBAz"
};
export default ___CSS_LOADER_EXPORT___;
