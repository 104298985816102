import { FC, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AccommodationType, Type } from '../services/types/types';
import classes from '../style/accommodations.module.scss'
import { FcSearch } from "react-icons/fc"
import Form from "react-bootstrap/Form";
import { cities } from '../services/constants/cities';
import Cards from '../components/cards';
import useAreas from '../hooks/useAreas';
import { PaginatedItems } from '../components/pagination';
import useApiService from '../services/services';

const Accommodations: FC = () => {
  const apiService = useApiService();

  const [accommodations, setAccommodations] = useState<AccommodationType[] | null>(null)
  const [id, setId] = useState('')
  const [ids, setIds] = useState([])
  const [status, setStatus] = useState('')
  const [typeId, setTypeId] = useState('')
  const [city, setCity] = useState('')
  const [area, setArea] = useState('')
  const [priceTo, setPriceTo] = useState('')
  const [priceFrom, setPriceFrom] = useState('')
  const [rooms, setRooms] = useState('')
  const [furnished, setFurnished] = useState('')
  const [types, setTypes] = useState<Type[]>([])
  const [resultsNotFound, setResultsNotFound] = useState(false)
  const filteredAreaItems = useAreas(city)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [params, setParams] = useState<URLSearchParams>(new URLSearchParams())

  useEffect(() => {
    const fetchData = async () => {
      const typesResponse = await apiService.getTypes();
      const idsResponse = await apiService.getIds();

      if (idsResponse.status === 200) {
        setIds(idsResponse.data.ids)
      }

      if (typesResponse.status === 200) {
        setTypes(typesResponse.data.types)
      }
    }
    fetchData().catch(console.error);
  }, [])


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const params = new URLSearchParams()
    if (status) {
      params.append("status", status)
    }
    if (typeId) {
      params.append("typeId", typeId)
    }
    if (id) {
      params.append("id", id)
    }
    if (city) {
      params.append("city", city)
    }
    if (area) {
      params.append("area", area)
    }
    if (priceTo) {
      params.append("priceTo", priceTo)
    }
    if (priceFrom) {
      params.append("priceFrom", priceFrom)
    }
    if (rooms) {
      params.append("rooms", rooms)
    }
    if (furnished) {
      params.append("furnished", furnished)
    }

    setParams(params)
    setCurrentPage(0)
  }

  const onChangePage = async (pageNumber: number) => {
    setCurrentPage(pageNumber)
    setParams((current) => {
      current.set('pageNumber', (pageNumber + 1).toString())
      return new URLSearchParams(current)
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      const accommodationsResponse = await apiService.getAccommodations(params.toString());
      if (accommodationsResponse.status === 200) {
        const { data: accommodations, total } = accommodationsResponse.data;
        setAccommodations(accommodations!)
        setResultsNotFound(accommodations!.length === 0)
        if (total) {
          setTotal(total!)
        }
      }
    }

    fetchData().catch(console.error);

  }, [params])


  return (
    <>
      {
        <div className={classes.filter}>
          <Form onSubmit={handleSubmit} className={`${classes.filterForm} pb-4`}>
            <div className="row">
              <Form.Group className="mb-3 col-6 col-md-2">
                <Form.Select id="status" placeholder="Id" value={id} onChange={(e) => setId(e.target.value)}>
                  <option value="">Id</option>
                  {ids?.map(({ id }) =>
                  (
                    <option key={id} value={id}>{id}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3 col-6 col-md-2">
                <Form.Select id="status" placeholder="Status" value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="">Status</option>
                  <option value="Izdavanje">Izdavanje</option>
                  <option value="Prodaja">Prodaja</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3 col-12 col-md-2">
                <Form.Select id="typeId" value={typeId} onChange={(e) => setTypeId(e.target.value)}>
                  <option value="" >Vrsta</option>
                  {types.length > 0 && types.map((type) => <option key={type.type_id} value={type.type_id}>{type.name}</option>)}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3 col-6 col-md-3">
                <Form.Control autoComplete='off' list="cities" type="text" id="city" placeholder="Grad.." value={city} onChange={(e) => setCity(e.target.value)} />
                <datalist id="cities">
                  {
                    Object.keys(cities).map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))
                  }
                </datalist>
              </Form.Group>
              <Form.Group className="mb-3 col-6 col-md-3">
                <Form.Control autoComplete='off' onChange={(e) => setArea(e.target.value)} value={area} list="filteredAreaItems" type="text" id="area" placeholder="Naselje.." />
                <datalist id="filteredAreaItems">
                  {
                    filteredAreaItems.map((area) => (
                      <option key={area} value={area}>
                        {area}
                      </option>
                    ))}
                </datalist>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="mb-3 col-4 col-md-2">
                <Form.Control id="from" onChange={(e) => setPriceFrom(e.target.value)} value={priceFrom} placeholder="Od" type="number" step="0.01" />
              </Form.Group>
              <Form.Group className="mb-3 col-4 col-md-2">
                <Form.Control id="to" onChange={(e) => setPriceTo(e.target.value)} value={priceTo} placeholder="Do" type="number" step="0.01" />
              </Form.Group>
              <Form.Group className="mb-3 col-4 col-md-2">
                <Form.Control id="rooms" placeholder="Broj soba.." type="number" value={rooms} onChange={(e) => setRooms(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3 col-6 col-md-3">
                <Form.Select id="furnished" value={furnished} onChange={(e) => setFurnished(e.target.value)}>
                  <option value="" >Opremljenost</option>
                  <option value="1">Namješten</option>
                  <option value="0.5">Polunamješten</option>
                  <option value="0">Nenamješten</option>
                </Form.Select>
              </Form.Group>
              <div className='mb-3 col-6 col-md-3'>
                <Button variant="secondary" className={`myBtn w-100`} type="submit">
                  Filtriraj
                </Button>
              </div>
            </div>
          </Form >
        </div>
      }
      {resultsNotFound && <div className="noFound"><FcSearch /><span>Nije pronađen rezultat</span></div>}
      <Cards accommodations={accommodations} />

      {total > 0 && <PaginatedItems onChangePage={onChangePage} total={total} itemsPerPage={20} currentPage={currentPage} />}
    </>
  );
};

export default Accommodations;
