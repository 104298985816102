import { RxDimensions } from "react-icons/rx"
import { BiBed } from "react-icons/bi"
import { TbBath } from "react-icons/tb"
import { IoMdCheckmarkCircleOutline, IoIosWarning } from "react-icons/io"
import classes from "../style/cards.module.scss";
import { AccommodationType } from '../services/types/types';
import { useNavigate } from "react-router-dom";
import { FILES_URL } from "../services/constants/endpoints";
import EstitorIcon from "./EstitorIcon";

function Cards({ accommodations }: { accommodations: AccommodationType[] | null }) {
    const navigate = useNavigate()
    return (
        <div className={classes.cards}>
            {accommodations && accommodations.map((accommodation) => {
                return (
                    <div className={classes.card} key={accommodation.id} onClick={() => navigate("" + accommodation.id)}>
                        <div className={classes.cardPhotoWrapper}>
                            <img src={FILES_URL + accommodation.featuredImage} alt="" srcSet="" />
                        </div>
                        <div className={classes.estitorState}>
                            {accommodation.estitor && !accommodation.dirtyEstitor && <EstitorIcon fill="#14A44D" />}
                            {accommodation.estitor && accommodation.dirtyEstitor && <EstitorIcon fill="#E4A11B" />}
                            {!accommodation.estitor && <EstitorIcon fill="#DC4C64" />}
                        </div>
                        <div className={classes.cardType}><span>{accommodation.status}</span></div>
                        <div className={classes.location}>
                            <img src="/images/home/location.png" alt="" srcSet="" />
                            <span className="ml-2">{accommodation.area}</span>
                        </div>
                        <div className={classes.bottom}>
                            <div className={classes.bottomLeft}>
                                <BiBed color="rgb(107 107 107)" />
                                <span>{accommodation.rooms}</span>
                                <TbBath color="rgb(107 107 107)" />
                                <span>{accommodation.bathrooms}</span>
                                <RxDimensions color="rgb(107 107 107)" />
                                <span>{accommodation.quadrature}&#13217;</span>
                            </div>
                            <div className={classes.price}><span>{accommodation.price}€</span></div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Cards