import { ChangeEvent, CSSProperties, FC, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { cities } from '../services/constants/cities';
import { CharacteristicType, Type } from '../services/types/types';
import classes from '../style/newAccommodation.module.scss'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import useAreas from '../hooks/useAreas';
import GalleryCarousel from '../components/GalleryCarousel';
import useApiService from '../services/services';
import { BeatLoader } from 'react-spinners';
import CoverPhoto from '../components/CoverPhoto';

const override: CSSProperties = {
  display: "block",
  margin: "0 6px",
  borderColor: "red",
};

const NewAccommodation: FC = () => {
  const apiService = useApiService();

  const navigate = useNavigate()
  const [types, setTypes] = useState<Type[]>([]);
  const [characteristics, setCharacteristics] = useState<CharacteristicType[]>([]);
  const [typeId, setTypeId] = useState('1')
  const [city, setCity] = useState('')
  const [area, setArea] = useState('')
  const [description, setDescription] = useState('')
  const [status, setStatus] = useState('Izdavanje')
  const [rooms, setRooms] = useState('')
  const [furnished, setFurnished] = useState('1')
  const [price, setPrice] = useState("")
  const [quadrature, setQuadrature] = useState('')
  const [bathrooms, setBathrooms] = useState('')
  const [note, setNote] = useState("")
  const [owner, setOwner] = useState("")
  const [ownerNumber, setOwnerNumber] = useState("")
  const [characteristicsIds, setCharacteristicsIds] = useState<number[]>([])
  const [floor, setFloor] = useState('')
  const [hidden, setHidden] = useState("0")
  const [title, setTitle] = useState("")
  const filteredAreaItems = useAreas(city)
  const [uploadFileList, setUploadFileList] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false)
  const [coverPhoto, setCoverPhoto] = useState<File | string | null>(null);

  useEffect(() => {
    (async () => {
      const typeResponse = await apiService.getTypes();
      const characteristicsResponse = await apiService.getCharacteristics();

      if (
        typeResponse.status === 200 &&
        characteristicsResponse.status === 200
      ) {
        setTypes(typeResponse.data.types);
        setCharacteristics(characteristicsResponse.data.characteristics);
      }
    })();
  }, []);

  const toggleHidden = () => {
    setHidden(hidden === "0" ? "1" : "0");
  }

  const handleCharacteristicsArray = (id: number) => {
    let array: number[] = characteristicsIds;
    if (characteristicsIds.includes(id)) {
      const index = array.indexOf(id);
      array.splice(index, 1);
    } else {
      array.push(id)
    }
    setCharacteristicsIds(array)
  }
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (coverPhoto === null) {
      Swal.fire('Neuspjesno!', `Naslovna slika je obavezna`, 'error');
      return;
    }
    setIsLoading(true)
    const data = new FormData()
    data.append("status", status)
    data.append("typeId", typeId);
    data.append("area", area)
    data.append("city", city)
    data.append("description", description)
    data.append("rooms", rooms)
    data.append("bathrooms", bathrooms)
    data.append("furnished", furnished)
    data.append("hidden", hidden)
    data.append("quadrature", quadrature)
    data.append("price", price)
    data.append("owner", owner)
    data.append("owner_number", ownerNumber)
    data.append("title", title)
    if (characteristicsIds.length > 0) {
      data.append("characteristicsIds", JSON.stringify(characteristicsIds));
    }
    if (floor) {
      data.append("floor", floor)
    }
    if (note) {
      data.append("note", note)
    }
    if (coverPhoto && typeof (coverPhoto) !== "string") {
      data.append("file", coverPhoto)
    }

    try {
      const accommodationsResponse = await apiService.createAccommocation(data)
      if (accommodationsResponse.status === 201) {
        const { accommodation } = accommodationsResponse.data;
        if (uploadFileList.length > 0) {
          const formData = new FormData()
          uploadFileList.forEach((file) => formData.append('files', file))
          try {
            await apiService.uploadFiles(accommodation.id, formData)
          } catch (error) {
            console.log(error)
          }
        }
        console.log(accommodationsResponse.data)
        let estitorMessage = ""
        Swal.fire({
          title: 'Da li zelite da se izbaci oglas na estitor?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Da, izbaci',
          cancelButtonText: 'Ne'
        }).then(async (result) => {
          if (!result.isConfirmed) {
            return
          }
          try {
            const estitorCreate = await apiService.createAdOnEstitor(accommodation.id)
            if (estitorCreate.data.success === 1) {
              estitorMessage = "i uspjesno dodato na estitor"
            } else {
              estitorMessage = "i neuspjesno dodato na estitor"
            }

          } catch (error) {
            console.log(error)
            estitorMessage = "i neuspjesno dodato na estitor"
          }

        })
          .finally(() => {
            Swal.fire('Uspješno dodato!', `Nekretnina je dodata u listi sa ${accommodation.id} id-em ` + estitorMessage, 'success');
            setIsLoading(false);
            navigate('/accommodations');
          });
      }
    } catch (error) {
      Swal.fire('Neuspješno dodato!', `Nije dodata nekretnina`, 'error');
    }
  }

  const handleRemovePhotoFromUpload = async (fileName: string) => {
    setUploadFileList((fileList) => fileList.filter(file => file.name !== fileName))
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFiles = e.target.files
      setUploadFileList((fileList) => [...newFiles, ...fileList])
    }
  };

  const handleCoverPhoto = async (newFile: File) => {
    setCoverPhoto(newFile);
  };

  const handleDeleteCoverPhoto = () => {
    setCoverPhoto(null)
  }

  return (
    <Form id='form-accommodation' onSubmit={handleSubmit} className={classes.formNewAccommodation}>
      <h2 className="header">Nova nekretnina</h2>
      <hr />
      <Form.Group
        className="mb-3"
        controlId="title"
      >
        <Form.Label>Naslov nekretnine</Form.Label>
        <Form.Control onChange={(e) => setTitle(e.target.value)} value={title} required type="text" />
      </Form.Group>
      <Form.Label htmlFor='status'>Status</Form.Label>
      <Form.Select onChange={(e) => setStatus(e.target.value)} value={status} className="mb-3" id="status" required>
        <option value="Izdavanje">Izdavanje</option>
        <option value="Prodaja">Prodaja</option>
      </Form.Select>
      <Form.Label htmlFor='type'>Tip nekretnine</Form.Label>
      <Form.Select onChange={(e) => setTypeId(e.target.value)} value={typeId} className="mb-3" id="type" required>
        {types.length > 0 &&
          types.map((type) => (
            <option key={type.type_id} value={type.type_id}>
              {type.name}
            </option>
          ))}
      </Form.Select>
      <Form.Group className="mb-3">
        <Form.Label htmlFor='city'>Grad</Form.Label>
        <Form.Control autoComplete='off' onChange={(e) => setCity(e.target.value)} value={city} list="cities" type="text" id="city" required />
        <datalist id="cities">
          {
            Object.keys(cities).map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))
          }
        </datalist>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Naselje</Form.Label>
        <Form.Control autoComplete='off' onChange={(e) => setArea(e.target.value)} value={area} list="filteredAreaItems" type="text" id="area" required />
        <datalist id="filteredAreaItems">
          {
            filteredAreaItems.map((area) => (
              <option key={area} value={area}>
                {area}
              </option>
            ))}
        </datalist>
      </Form.Group>
      <Form.Group
        className="mb-3"
        controlId="description"
      >
        <Form.Label>Opis nekretnine</Form.Label>
        <Form.Control onChange={(e) => setDescription(e.target.value)} value={description} required as="textarea" rows={3} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="price">
        <Form.Label>Cijena nekretnine</Form.Label>
        <Form.Control onChange={(e) => setPrice(e.target.value)} value={price} required type="number" step="0.01" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="quadrature">
        <Form.Label>Kvadratura</Form.Label>
        <Form.Control onChange={(e) => setQuadrature(e.target.value)} value={quadrature} required type="number" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="rooms">
        <Form.Label>Broj soba</Form.Label>
        <Form.Control onChange={(e) => setRooms(e.target.value)} value={rooms} required type="number" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="bathrooms">
        <Form.Label>Broj kupatila</Form.Label>
        <Form.Control onChange={(e) => setBathrooms(e.target.value)} value={bathrooms} required type="number" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="floor">
        <Form.Label>Sprat</Form.Label>
        <Form.Control onChange={(e) => setFloor(e.target.value)} value={floor} required type="number" />
      </Form.Group>
      <Form.Label htmlFor='furnished'>Opremljenost nekretnine</Form.Label>
      <Form.Select value={furnished} onChange={(e) => setFurnished(e.target.value)} id="furnished" className="mb-3" required>
        <option value="1">Namješten</option>
        <option value="0.5">Polunamješten</option>
        <option value="0">Nenamješten</option>
      </Form.Select>
      <hr />
      <div className={classes.radioButtons}>
        {characteristics.length > 0 &&
          characteristics.map((characteristic) => (
            <Form.Check onChange={() => handleCharacteristicsArray(characteristic.id)}
              className="m-3 flex-fill"
              type="checkbox"
              id={characteristic.id.toString()}
              key={characteristic.id}
              label={characteristic.name}
            />
          ))}
      </div>
      <hr />
      <h5 className="mb-3">Podaci o vlasniku</h5>
      <Form.Group className="mb-3" controlId="owner">
        <Form.Label>Ime i prezime vlasnika</Form.Label>
        <Form.Control onChange={(e) => setOwner(e.target.value)} required value={owner} type="text" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="owner_number">
        <Form.Label>Broj telefona vlasnika</Form.Label>
        <Form.Control onChange={(e) => setOwnerNumber(e.target.value)} required value={ownerNumber} type="tel" />
      </Form.Group>
      <Form.Group
        className="mb-3"
        controlId="note"
      >
        <Form.Label>Bilješka</Form.Label>
        <Form.Control onChange={(e) => setNote(e.target.value)} value={note} as="textarea" rows={3} />
      </Form.Group>
      <hr />
      <Form.Group className="mb-3">
        <Form.Label>Naslovna slika</Form.Label>
        <br />
        <CoverPhoto onDelete={handleDeleteCoverPhoto} onChange={handleCoverPhoto} coverPhoto={coverPhoto} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Izaberi ostale slike</Form.Label>
        <br />
        <input id="files" type="file" multiple onChange={(e) => handleFileChange(e)} />
      </Form.Group>
      {(uploadFileList.length > 0) &&
        <GalleryCarousel
          files={[]}
          uploadedFiles={uploadFileList}
          onDeleteFile={() => void 0}
          onDeleteUploaded={handleRemovePhotoFromUpload} />
      }
      <hr />
      <Form.Check onChange={toggleHidden} value={hidden}
        className="mb-3"
        type="switch"
        id="hidden"
        label="Arhiviraj nekretninu"
      />
      <Button variant='secondary' disabled={isLoading} className="myBtn mt-3 w-100" type="submit">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {isLoading ?
            <BeatLoader
              color={"#ffffff"}
              loading={isLoading}
              cssOverride={override}
              size={14}
              aria-label="Loading Spinner"
              data-testid="loader"
            /> : "Sačuvaj"}
        </div>
      </Button>
    </Form>
  );
};

export default NewAccommodation;