// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pagination_paginationBttns__XnQlh ul {\n  height: 40px;\n  list-style: none;\n  display: flex;\n  justify-content: center;\n  padding: 0;\n}\n.pagination_paginationBttns__XnQlh ul .pagination_active__ZOqSs a {\n  color: white;\n  background-color: #E47827;\n}\n.pagination_paginationBttns__XnQlh ul a {\n  padding: 5px 10px 7px;\n  margin: 8px;\n  border-radius: 50%;\n  border: 1px solid rgba(212, 207, 203, 0.2392156863);\n  color: #000000;\n  font-weight: bold;\n  cursor: pointer;\n  background-color: rgba(212, 207, 203, 0.2392156863);\n}\n.pagination_paginationBttns__XnQlh ul a:hover {\n  color: white !important;\n  background-color: #E47827;\n}", "",{"version":3,"sources":["webpack://./src/style/pagination.module.scss","webpack://./src/style/variables/colors.scss"],"names":[],"mappings":"AAGI;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,UAAA;AAFR;AAIQ;EACI,YAAA;EACA,yBCZH;ADUT;AAKQ;EACI,qBAAA;EACA,WAAA;EACA,kBAAA;EACA,mDAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;EACA,mDAAA;AAHZ;AAKY;EACI,uBAAA;EACA,yBC3BP;ADwBT","sourcesContent":["@import 'variables/colors.scss';\n\n.paginationBttns {\n    ul {\n        height: 40px;\n        list-style: none;\n        display: flex;\n        justify-content: center;\n        padding: 0;\n\n        .active a {\n            color: white;\n            background-color: $orange;\n        }\n\n        a {\n            padding: 5px 10px 7px;\n            margin: 8px;\n            border-radius: 50%;\n            border: 1px solid #d4cfcb3d;\n            color: #000000;\n            font-weight: bold;\n            cursor: pointer;\n            background-color: #d4cfcb3d;\n\n            &:hover {\n                color: white !important;\n                background-color: $orange;\n            }\n\n        }\n\n    }\n\n}","$orange: #E47827;\n$green: #0f574f;\n$green2: #357f77;\n$gray: #747575;\n$baige: #79726a;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationBttns": "pagination_paginationBttns__XnQlh",
	"active": "pagination_active__ZOqSs"
};
export default ___CSS_LOADER_EXPORT___;
