// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".newAccommodation_formNewAccommodation__9T5J6 {\n  height: 100%;\n  width: 1000px;\n  max-width: calc(100% - 20px);\n  border-radius: 20px;\n  margin: auto;\n}\n.newAccommodation_formNewAccommodation__9T5J6 .newAccommodation_radioButtons__j0gRv {\n  display: flex;\n  flex-wrap: wrap;\n}\n@media (max-width: 540px) {\n  .newAccommodation_formNewAccommodation__9T5J6 .newAccommodation_radioButtons__j0gRv {\n    justify-content: center;\n  }\n}", "",{"version":3,"sources":["webpack://./src/style/newAccommodation.module.scss","webpack://./src/style/variables/breakpoints.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,aAAA;EACA,4BAAA;EACA,mBAAA;EACA,YAAA;AADJ;AAGI;EACI,aAAA;EACA,eAAA;AADR;ACaI;EDdA;IAKQ,uBAAA;EAAV;AACF","sourcesContent":["@import 'variables/breakpoints.scss';\n\n.formNewAccommodation {\n    height: 100%;\n    width: 1000px;\n    max-width: calc(100% - 20px);\n    border-radius: 20px;\n    margin: auto;\n\n    .radioButtons {\n        display: flex;\n        flex-wrap: wrap;\n\n        @include sm {\n            justify-content: center;\n        }\n    }\n}\n","$breakpoints: (\n    'xxs': 400px,\n    'xs': 480px,\n    'sm': 540px,\n    'md': 720px,\n    'lg': 834px,\n    'xl': 1100px,\n    'xxl': 1220px,\n);\n\n@mixin xxs {\n    @media(max-width:map-get($breakpoints, 'xxs')) {\n        @content\n    }\n}\n\n@mixin xs {\n    @media(max-width:map-get($breakpoints, 'xs')) {\n        @content\n    }\n}\n\n@mixin sm {\n    @media(max-width:map-get($breakpoints, 'sm')) {\n        @content\n    }\n}\n\n@mixin md {\n    @media(max-width:map-get($breakpoints, 'md')) {\n        @content\n    }\n}\n\n@mixin lg {\n    @media(max-width:map-get($breakpoints, 'lg')) {\n        @content\n    }\n}\n\n@mixin xl {\n    @media(max-width:map-get($breakpoints, 'xl')) {\n        @content\n    }\n}\n\n@mixin xxl {\n    @media(max-width:map-get($breakpoints, 'xxl')) {\n        @content\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formNewAccommodation": "newAccommodation_formNewAccommodation__9T5J6",
	"radioButtons": "newAccommodation_radioButtons__j0gRv"
};
export default ___CSS_LOADER_EXPORT___;
